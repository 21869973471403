import axios from "axios";

//  Function to call the api to validate the user credentials
export const login = async (values) => {
  try {
    const { data } = await axios.post(
      //"https://election-data-app.vantiqa.com/api/login",
      "https://search-app.vantiqa.com/api/login",
      //"https://search-app.vantiqa.com/api/login",
      //"http://localhost:5000/api/login",
      values
    );
    return data;
  } catch (error) {
    throw error.response.data.error;
  }
};

// Function to call the api to validate the otp after successful login

export const otpCheck = async (info) => {
  try {
    const { data } = await axios.post(
      // "https://election-data-app.vantiqa.com/api/otpcheck",
      "https://search-app.vantiqa.com/api/otpcheck",
      //"https://search-app.vantiqa.com/api/otpcheck",
      //"http://localhost:5000/api/otpcheck",

      info
    );
    return data;
  } catch (error) {
    throw error.response.data.error;
  }
};
