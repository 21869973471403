import axios from "axios";
import { categoryMapping } from "../../data/searchpage/data";
import Cookies from "js-cookie";

export const FetchingCategoryDetails = async (
  linkedin_id,
  decodedID,
  category,
  id_company
) => {
  if (id_company === undefined) {
    id_company = "";
  }

  if (linkedin_id === undefined) {
    linkedin_id = "";
  }

  const data = {
    linkedin_id: linkedin_id,
    id_master: decodedID,
    category: categoryMapping[category],
    id_company: id_company,
  };

  try {
    const response = await axios.post(
      // for production
      // "https://search-app.vantiqa.com/api/category",
      "https://search-app.vantiqa.com/api/category",
      //"http://127.0.0.1:5000/api/category",

      data,
      {
        headers: {
          Authorization: `Bearer ${Cookies.get("ElectionToken")}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
//////////////////////////////////////////////////////////////////////////////
export const ProfileHeaderData = async (decodedID) => {
  //const url = `https://search-app.vantiqa.com/api/userprofile/${decodedID}`;
  const url = `https://search-app.vantiqa.com/api/userprofile/${decodedID}`;
  //const url = `http://localhost:5000/api/userprofile/${decodedID}`;

  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${Cookies.get("ElectionToken")}`,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
////////////////////////////////////////////////////////////////////////////////
export const FetchingUserFamilyData = async (uid) => {
  try {
    const response = await axios.get(
      //`https://search-app.vantiqa.com/api/userfamily/${uid}`,
      `https://search-app.vantiqa.com/api/userfamily/${uid}`,
      //`http://localhost:5000/api/userfamily/${uid}`,
      {
        headers: {
          Authorization: `Bearer ${Cookies.get("ElectionToken")}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
