import PersonIcon from "@mui/icons-material/Person";
import EventIcon from "@mui/icons-material/Event";
import ContactPhoneIcon from "@mui/icons-material/ContactPhone";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import MapsHomeWorkIcon from "@mui/icons-material/MapsHomeWork";
import BallotIcon from "@mui/icons-material/Ballot";
import SensorOccupiedIcon from "@mui/icons-material/SensorOccupied";
import WorkIcon from "@mui/icons-material/Work";
import BadgeIcon from "@mui/icons-material/Badge";

export const searchDataInitalValues = {
  tableName: "masterdata",
  full_name_current: "",
  full_name_at_birth: "",
  mother_given_names_at_birth: "",
  father_given_names_at_birth: "",
  date_of_birth: "",
  gender: "",
  ethnic_group: "",
  religion: "",
  marital_status: "",
  street_name: "",
  community: "",
  municipality: "",
  year: "",
  month: "",
  day: "",
  parliamentary_electoral_district: "",
  apartment_number: "",
  blk_bld_phase: "",
  polling_division: "",
  municipal_electoral_district: "",
  registration_area: "",
  corporation: "",
  phone_number: "",
  email: "",
  relationship_to_head_of_household: "",
  number_of_persons_in_household: "",
  person_number: "",

  filterColumn: "",
  order: "",
  FilterStatments: "",
};
export const defaultColumns = [
  {
    name: "full_name_current",
    label: "Current Full Name",
    visible: true,
  },
  {
    name: "full_name_at_birth",
    label: "Full Name At Birth",
    visible: true,
  },
  {
    name: "mother_given_names_at_birth",
    label: "Mother's Given Names At Birth",
    visible: true,
  },
  {
    name: "father_given_names_at_birth",
    label: "Father's Given Names At Birth",
    visible: true,
  },
  {
    name: "date_of_birth",
    label: "Date Of Birth",
    visible: true,
  },
  {
    name: "gender",
    label: "Gender",
    visible: true,
  },
  {
    name: "ethnic_group",
    label: "Ethnic Group",
    visible: true,
  },
  {
    name: "religion",
    label: "Religion",
    visible: true,
  },
  {
    name: "marital_status",
    label: "Marital Status",
    visible: true,
  },
  {
    name: "street_name",
    label: "Street Name",
    visible: true,
  },
  {
    name: "community",
    label: "Community",
    visible: true,
  },
  {
    name: "municipality",
    label: "Municipality",
    visible: true,
  },
  {
    name: "parliamentary_electoral_district",
    label: "Parliamentary Electoral District",
    visible: true,
  },
  {
    name: "apartment_number",
    label: "Apartment Number",
    visible: true,
  },
  {
    name: "blk_bld_phase",
    label: "Blk/Bld Phase",
    visible: true,
  },
  {
    name: "polling_division",
    label: "Polling Division",
    visible: true,
  },
  {
    name: "municipal_electoral_district",
    label: "Municipal Electoral District",
    visible: true,
  },
  {
    name: "registration_area",
    label: "Registration Area",
    visible: true,
  },
  {
    name: "corporation",
    label: "Corporation",
    visible: true,
  },
  {
    name: "phone_number",
    label: "Phone Number",
    visible: true,
  },
  {
    name: "email",
    label: "Email",
    visible: true,
  },
  {
    name: "relationship_to_head_of_household",
    label: "Relationship To Head Of Household",
    visible: true,
  },
  {
    name: "number_of_persons_in_household",
    label: "Number Of Persons In Household",
    visible: true,
  },
  {
    name: "person_number",
    label: "Person Number",
    visible: true,
  },
];

export const categories = {
  Name: [
    { value: "full_name_current", name: "Name" },
    { value: "full_name_at_birth", name: "Full Name At Birth" },
    {
      value: "father_given_names_at_birth",
      name: "Father's Given Name At Birth",
    },
    {
      value: "mother_given_names_at_birth",
      name: "Mother's Given Name At Birth",
    },
  ],
  Age: [{ value: "date_of_birth", name: "Date Of Birth" }],
  Demographics: [
    { value: "gender", name: "Gender" },
    { value: "ethnic_group", name: "Ethnic Group" },
    { value: "religion", name: "Religion" },
    { value: "marital_status", name: "Marital Status" },
  ],
  Contact: [
    { value: "phone_number", name: "Phone Number" },
    { value: "email", name: "Email" },
  ],
  Address: [
    { value: "street_name", name: "Street Name" },

    { value: "community", name: "Community" },
    { value: "municipality", name: "Municipality" },
    { value: "corporation", name: "Corporation" },
    {
      value: "parliamentary_electoral_district",
      name: "Parliamentary Electoral District",
    },
    { value: "apartment_number", name: "Apartment Number" },
    { value: "blk_bld_phase", name: "Blk/bld phase" },
    { value: "polling_division", name: "Polling Division" },
    { value: "registration_area", name: "Registration Area" },
    {
      value: "municipal_electoral_district",
      name: "Municipal Electoral District",
    },
  ],
  Social: [
    { value: "facebook_profile_url", name: "Facebook Profile Url" },
    { value: "linkedin_profile_url", name: "Linkedin Profile Url" },
  ],
  "Household Info": [
    {
      value: "relationship_to_head_of_household",
      name: "Relationship To Head Of Household",
    },
    {
      value: "number_of_persons_in_household",
      name: "Number Of Persons In Household",
    },
    {
      value: "person_number",
      name: "Person Number",
    },
  ],
  Professional: [
    {
      value: "profile_title",
      name: "Title",
    },
    {
      value: "profile_summary",
      name: "Summary",
    },
    {
      value: "skills",
      name: "Skills",
    },
    {
      value: "languages",
      name: "Languages",
    },
    {
      value: "honors",
      name: "Honors",
    },
    {
      value: "certifications",
      name: "Certifications",
    },
    {
      value: "publications",
      name: "Publications",
    },
    {
      value: "contact_url_portfolio",
      name: "Portfolio URL",
    },
    {
      value: "contact_url_blog",
      name: "Personal Blog URL",
    },
    {
      value: "contact_url_rss",
      name: "RSS Feed URL",
    },
    {
      value: "contact_url_other",
      name: "Other Contact Information URL",
    },
  ],
  "Career Info": [
    { value: "experience_json", name: "Experience" },
    { value: "education_json", name: "Education" },
  ],
};

export const categoryIcons = {
  Name: <PersonIcon />,
  Age: <EventIcon />,
  Demographics: <SensorOccupiedIcon />,
  Contact: <ContactPhoneIcon />,
  Address: <LocationOnIcon />,
  "Household Info": <MapsHomeWorkIcon />,
  "Election Info": <BallotIcon />,
  Professional: <BadgeIcon />,
  "Career Info": <WorkIcon />,
  Companies: <WorkIcon />,
};

export const CareerInfoFlagFields = [
  "profile_link",
  "contact_url_company",
  "profile_title",
  "profile_summary",
  "skills",
  "languages",
  "honors",
  "publications",
  "certifications",
  "contact_url_portfolio",
  "contact_url_blog",
  "contact_url_rss",
  "contact_url_other",
  "experience_json",
  "education_json",
];
export const fieldsToCheck = [
  "full_name_current",
  "full_name_at_birth",
  "mother_given_names_at_birth",
  "father_given_names_at_birth",
  "date_of_birth",
  "gender",
  "ethnic_group",
  "religion",
  "marital_status",
  "street_name",
  "community",

  "municipality",
  "parliamentary_electoral_district",
  "apartment_number",
  "blk_bld_phase",
  "polling_division",
  "municipal_electoral_district",
  "registration_area",
  "corporation",
  "phone_number",
  "email",
  "relationship_to_head_of_household",
  "number_of_persons_in_household",
  "person_number",
  "year",
  "month",
  "day",
  "profile_link",
  "contact_url_company",
  "profile_title",
  "profile_summary",
  "skills",
  "languages",
  "honors",
  "publications",
  "certifications",
  "contact_url_portfolio",
  "contact_url_blog",
  "contact_url_rss",
  "contact_url_other",
  "experience_json",
  "education_json",
  "facebook_profile_url",
  "linkedin_profile_url",
];

///////////// fields for profile

export const categoryMapping = {
  Name: "NAME",
  Address: "ADDRESS",
  Age: "AGE",
  Demographics: "DEMOGRAPHICS",
  Contact: "CONTACT",
  Social: "SOCIAL_PROFILE",
  Immigration: "IMMIGRATION",
  Disability: "DISABILITY",
  Health: "HEALTH",
  Fertility: "FERTILITY",
  "Household Info": "HOUSEHOLD_INFO",
  ICT: "ICT",
  Companies: "COMPANIES",
  Housing: "HOUSING",
  Environment: "ENVIRONMENT",
  "Election Info": "VOTERS_HISTORY",
  Professional: "PROFESSIONAL_PROFILE",
  Experience: "WORK_EXPERIENCE",
  Education: "EDUCATION",
  "Company Information": "COMP_INFO",
  "Company Structure": "COMP_STRCT",
  Legal: "COMP_LEGAL",
  "Contact Person": "COMP_CP",
  "Social Media": "COMP_SOCIAL",
  "Company Website": "COMP_WEBSITE",
  "Company Email": "COMP_EMAIL",
  "Company Phone": "COMP_PHONE",
  Addresses: "COMP_ADR",
  Funding: "FUNDING",
  "Financial Insights": "FINANCE",
  "Employee Details": "EMPLOYEE",
  "Digital Transformation": "DIGITAL",
  Occupation: "OCCUPATION",
  Schooling: "SCHOOLING",
  Employees: "EMPLOYEES",
};
export const Profilecategories = {
  Name: [],
  Address: [],
  Companies: [],
  Employees: [],
  Age: [],
  Demographics: [],
  "Family Tree": [],
  Contact: [],
  Social: [],
  Immigration: [],
  Disability: [],
  Health: [],
  Fertility: [],
  "Household Info": [],
  ICT: [],
  Housing: [],
  Environment: [],
  "Election Info": [],
  Professional: [],
  Experience: [],
  Education: [],
  "Company Information": [],
  "Company Structure": [],
  Legal: [],
  "Contact Person": [],
  "Social Media": [],
  "Company Website": [],
  "Company Email": [],
  "Company Phone": [],
  Addresses: [],
  Funding: [],
  "Financial Insights": [],
  "Employee Details": [],
  "Digital Transformation": [],
  Occupation: [],
  Schooling: [],
};

export const columnMapping = {
  "Family Tree": [],
  Companies: {
    companies: "Companies",
  },
  Name: {
    full_name_current: "Full Name Current",
    salutation: "Salutation",
    surname_at_birth: "Surname At Birth",
    full_name_at_birth: "Full Name At Birth",
    father_given_names_at_birth: "Father's Given Name",
    mother_given_names_at_birth: "Mother's Given Name",
    given_names_current: "Given Names Current",
    surname_current: "Surname Current",
    middle_names_current: "Middle Names Current",
    initials_current: "Initials Current",
    given_names_at_birth: "Given Names At Birth",
    initials_at_birth: "Initials At Birth",
  },
  Age: {
    date_of_birth: "Date of Birth",
    age: "Age",
    age_range: "Age Range",
    birth_registration_number: "Birth Registration Number",
  },

  Demographics: {
    gender: "Gender",
    marital_status: "Marital Status",
    ethnic_group: "Ethnic Group",
    religion: "Religion",
    religion_group: "Religion Group",
  },

  Address: {
    street_name: "Street Name",
    community: "Community",
    municipality: "Municipality",
    parliamentary_electoral_district: "Parliamentary Electoral District",
    apartment_number: "Apartment Number",
    blk_bld_phase: "Blk/Bld Phase",
    polling_division: "Polling Division",
    municipal_electoral_district: "Municipal Electoral District",
    registration_area: "Registration Area",
    corporation: "Corporation",
  },

  Contact: {
    phone_number: "Phone Number",
    email: "Email",
    mobile_number: "Mobile Number",
    home_number: "Home Number",
  },
  Social: {
    facebook_profile_url: "Facebook Profile Url",
    linkedin_profile_url: "Linkedin Profile Url",
  },
  Immigration: {
    place_of_birth: "Place of Birth",
    full_address_at_birth_community_code:
      "Full Address at Birth Community Code",
    full_address_at_birth_not_stated: "Full Address at Birth Not Stated",
    country_of_birth: "Country of Birth",
    duration_of_residence_in_trinidad_and_tobago:
      "Duration of Residence in Trinidad and Tobago",
    usual_residence: "Usual Residence",
    address_of_place_of_usual_residence_community_code:
      "Address of Place of Usual Residence Community Code",
    address_10_years_ago: "Address 10 Years Ago",
    address_10_years_ago_not_applicable: "Address 10 Years Ago Not Applicable",
    address_10_years_ago_community_code: "Address 10 Years Ago Community Code",
    residence_in_another_country: "Residence in Another Country",
    country_of_last_residence: "Country of Last Residence",
    year_left_trinidad_and_tobago: "Year Left Trinidad and Tobago",
    year_returned_trinidad_and_tobago: "Year Returned to Trinidad and Tobago",
    migration: "Migration",
    persons_migrated: "Persons Migrated",
  },
  Disability: {
    long_standing_disability: "Long Standing Disability",
    disability_seeing: "Disability Seeing",
    disability_hearing: "Disability Hearing",
    disability_walking: "Disability Walking",
    disability_remembering: "Disability Remembering",
    disability_gripping: "Disability Gripping",
    disability_speaking: "Disability Speaking",
    disability_seeing_severity: "Disability Seeing Severity",
    disability_hearing_severity: "Disability Hearing Severity",
    disability_walking_severity: "Disability Walking Severity",
    disability_remembering_severity: "Disability Remembering Severity",
    disability_gripping_severity: "Disability Gripping Severity",
    disability_speaking_severity: "Disability Speaking Severity",
    disability_affect_taking_care_of_himself_herself:
      "Disability Affect Taking Care Of Himself/Herself",
    disability_affect_getting_around_within_the_home:
      "Disability Affect Getting Around Within The Home",
    disability_affect_going_outside_the_home:
      "Disability Affect Going Outside The Home",
    disability_affect_working_at_a_job_or_business:
      "Disability Affect Working At A Job Or Business",
    disability_affect_undertaking_educational_activities:
      "Disability Affect Undertaking Educational Activities",
    disability_affect_participating_in_social_activities:
      "Disability Affect Participating In Social Activities",
    disability_no_effect: "Disability No Effect",
  },

  Health: {
    chronic_illness_none: "Chronic Illness None",
    chronic_illness_alzheimers: "Chronic Illness Alzheimers",
    chronic_illness_arthritis: "Chronic Illness Arthritis",
    chronic_illness_asthma: "Chronic Illness Asthma",
    chronic_illness_cancer: "Chronic Illness Cancer",
    chronic_illness_clinical_mental_disease:
      "Chronic Illness Clinical Mental Disease",
    chronic_illness_diabetes: "Chronic Illness Diabetes",
    chronic_illness_hiv_aids: "Chronic Illness HIV/AIDS",
    chronic_illness_heart_disease: "Chronic Illness Heart Disease",
    chronic_illness_hypertension: "Chronic Illness Hypertension",
    chronic_illness_kidney_disease: "Chronic Illness Kidney Disease",
    chronic_illness_lupus: "Chronic Illness Lupus",
    chronic_illness_parkinsons_disease: "Chronic Illness Parkinson's Disease",
    chronic_illness_sickle_cell_anemia: "Chronic Illness Sickle Cell Anemia",
    chronic_illness_other_illness: "Chronic Illness Other Illness",
    chronic_illness_not_stated: "Chronic Illness Not Stated",
    health_insurance: "Health Insurance",
  },

  Fertility: {
    live_born_children_totals: "Live Born Children Totals",
    live_born_children_males: "Live Born Children Males",
    live_born_children_females: "Live Born Children Females",
    live_born_children_not_stated: "Live Born Children Not Stated",
    still_alive_children_totals: "Still Alive Children Totals",
    still_alive_children_males: "Still Alive Children Males",
    still_alive_children_females: "Still Alive Children Females",
    still_alive_children_not_stated: "Still Alive Children Not Stated",
    age_first_birth: "Age First Birth",
    age_last_birth: "Age Last Birth",
    number_of_live_births_in_the_past_12_months:
      "Number Of Live Births In The Past 12 Months",
    live_born_children_died_in_the_past_12_months_totals:
      "Live Born Children Died In The Past 12 Months Totals",
    live_born_children_died_in_the_past_12_months_males:
      "Live Born Children Died In The Past 12 Months Males",
    live_born_children_died_in_the_past_12_months_not_stated:
      "Live Born Children Died In The Past 12 Months Not Stated",
    live_born_children_died_in_the_past_12_months_females:
      "Live Born Children Died In The Past 12 Months Females",
    number_of_still_births_in_the_past_12_months:
      "Number Of Still Births In The Past 12 Months",
    union_status: "Union Status",
  },

  "Household Info": {
    relationship_to_head_of_household: "Relationship To Head Of Household",
    number_of_persons_in_household: "Number Of Persons In Household",
    person_number: "Person Number",
  },
  ICT: {
    use_of_mobile_phone: "Use Of Mobile Phone",
    use_of_computer: "Use Of Computer",
    use_of_internet: "Use Of Internet",
  },
  Housing: {
    type_of_building: "Type Of Building",
    material_of_outer_walls: "Material Of Outer Walls",
    material_of_the_roof: "Material Of The Roof",
    year_built: "Year Built",
    type_of_dwelling: "Type Of Dwelling",
    "tenure/ownership_dwelling_unit": "Tenure/Ownership - Dwelling Unit",
    "tenure/ownership_land": "Tenure/Ownership - Land",
    monthly_rent_tenant: "Monthly Rent - Tenant",
    monthly_rent_owner: "Monthly Rent - Owner",
    monthly_rent_do_not_know: "Monthly Rent - Do Not Know",
    multiple_occupancy_of_dwelling_unit: "Multiple Occupancy Of Dwelling Unit",
    number_of_households: "Number Of Households",
    number_of_rooms: "Number Of Rooms",
    number_of_bedrooms_dwelling_unit: "Number Of Bedrooms - Dwelling Unit",
    number_of_bedrooms_household: "Number Of Bedrooms - Household",
    water_supply: "Water Supply",
    water_supply_public: "Water Supply - Public",
    toilet_facilities: "Toilet Facilities",
    toilet_facilities_sharing: "Toilet Facilities - Sharing",
    type_of_lighting: "Type Of Lighting",
    type_of_fuel: "Type Of Fuel",
    internet_none: "Internet - None",
    internet_analogue: "Internet - Analogue",
    internet_other_narrowband: "Internet - Other Narrowband",
    internet_digital_subscriber_line: "Internet - Digital Subscriber Line",
    internet_cable_modem: "Internet - Cable Modem",
    internet_other_fixed_broadband: "Internet - Other Fixed Broadband",
    internet_mobile_broadband: "Internet - Mobile Broadband",
    internet_not_known: "Internet - Not Knownt",
    facilities_air_conditioner: "Facilities - Air Conditioner",
    facilities_cable_tv_satelitte: "Facilities - Cable TV Satelitte",
    facilities_television: "Facilities - Television",
    facilities_dvd_mp3: "Facilities - DVD MP3",
    facilities_radio_stereo: "Facilities - Radio Stereo",
    facilities_washing_machine: "Facilities - Washing Machine",
    facilities_clothes_dryer: "Facilities - Clothes Dryer",
    facilities_microwave: "Facilities - Microwave",
    facilities_refrigerator_freezer: "Facilities - Refrigerator Freezer",
    facilities_leisure_boat_yacht: "Facilities - Leisure Boat Yacht",
    facilities_swimming_pool: "Facilities - Swimming Pool",
    facilities_vehicle_for_private_use: "Facilities - Vehicle For Private Use",
  },
  Environment: {
    disposal_white_goods: "Disposal - White Goods",
    disposal_electronic: "Disposal - Electronic",
    disposal_organic: "Disposal - Organic",
    disposal_glass: "Disposal - Glass",
    disposal_plastic: "Disposal - Plastic",
    disposal_paper: "Disposal - Paper",
    disposal_tyres: "Disposal - Tyres",
    disposal_vegetation: "Disposal - Vegetation",
    disposal_not_stated: "Disposal - Not Stated",
    disposal_method_white_goods: "Disposal Method - White Goods",
    disposal_method_electronic: "Disposal Method - Electronic",
    disposal_method_organics: "Disposal Method - Organics",
    disposal_method_glass: "Disposal Method - Glass",
    disposal_method_plastic: "Disposal Method - Plastic",
    disposal_method_paper: "Disposal Method - Paper",
    disposal_method_tyres: "Disposal Method - Tyres",
    disposal_method_vegetation: "Disposal Method - Vegetation",
    environmental_issues_none: "Environmental Issues - None",
    environmental_issues_solid_waste: "Environmental Issues - Solid Waste",
    environmental_issues_water_contamination:
      "Environmental Issues - Water Contamination",
    environmental_issues_drainage: "Environmental Issues - Drainage",
    environmental_issues_air_pollution: "Environmental Issues - Air Pollution",
    environmental_issues_pesticide: "Environmental Issues - Pesticide",
    environmental_issues_deforestation: "Environmental Issues - Deforestation",
    environmental_issues_destroyed_mangrove:
      "Environmental Issues - Destroyed Mangrove",
    environmental_issues_soil_erosion: "Environmental Issues - Soil Erosion",
    environmental_issues_flooding: "Environmental Issues - Flooding",
    environmental_issues_cell_tower: "Environmental Issues - Cell Tower",
    environmental_issues_sewerage: "Environmental Issues - Sewerage",
    environmental_issues_waste_water: "Environmental Issues - Waste Water",
    environmental_issues_excessive_noise:
      "Environmental Issues - Excessive Noise",
    environmental_issues_other: "Environmental Issues - Other",
    "environmental_issues_don’t_know": "Environmental Issues - Don’t Know",
    environmental_issues_not_stated: "Environmental Issues - Not Stated",
  },
  "Election Info": {
    registration_score: "Registration Probability Score",
    turn_out_score: "Turnout Likelihood Score",
    party_support_score: "Partisan Support Rating",
    swing_voter_label: "Swing Voter Rating",
    importance_label: "Importance Score",
    registered_for_election: "Registered For Election",
    unc_member_relation: "UNC Member Relationship",
    eligible_for_voting: "Eligible For Voting",
  },
  Professional: {
    profile_title: "Title",
    profile_summary: "Summary",
    skills: "Skills",
    languages: "Languages",
    honors: "Honors",
    certifications: "Certifications",
    publications: "Publications",
    contact_url_portfolio: "Portfolio URL",
    contact_url_blog: "Personal Blog URL",
    contact_url_rss: "RSS Feed URL",
    contact_url_other: "Other Contact Information URL",
  },
  Experience: {
    experience_json: "Experience",
  },
  Education: {
    education_json: "Education",
  },
  Employees: {
    employees: "Employees",
  },
  "Company Information": {
    company_name: "Company Name",
    company_other_business_names: "Other Business Names",
    comapny_describtion: "Description",
    company_industry: "Industry",
    company_sic_standard_industrial_classification: "SIC",
    company_year_established: "Year Established",
    company_year_began_operation: "Year Began Operation",
    company_legal_code: "Legal Code",
    company_ownership_code: "Ownership Code",
    company_products: "Products",
    company_logo_url_primary: "Logo Url Primary",
    company_logo_url_secondary: "Logo Url Secondary",
  },
  "Company Structure": {
    company_number_of_retail_locations: "Number of Retail Locations",
  },
  Legal: {
    company_incorporation_type: "Incorporation Type",
    company_legal_status: "Legal Status",
    company_company_identifier: "Company Identifier",
    comapny_irn: "IRN",
  },
  Addresses: {
    company_business_address: "Business Address",
    company_business_address_2: "Business Address 2",
    company_business_address_3: "Business Address 3",
    company_mailing_address: "Mailing Address",
    company_city: "City",
    company_country: "Country",
    company_postal_code: "Zip Code",
  },
  "Company Phone": {
    company_telephone_number: "Telephone Number",
    company_fax_number: "Fax Number",
  },
  "Company Email": {
    company_email: "Email",
  },
  "Company Website": {
    company_website: "Website",
  },
  "Social Media": {
    company_facebook_url: "Facebook URL",
    company_twitter_url: "Twitter URL",
    company_linkedin_url: "LinkedIn URL",
  },
  "Contact Person": {
    company_contact_person: "Contact Person",
    company_contact_position: "Contact Position",
  },
  Funding: {
    company_last_funding_date: "Last Funding Date",
    company_latest_funding_stage: "Latest Funding Stage",
    company_number_of_funding_rounds: "Number of Funding Rounds",
    company_last_funding_round_ammount: "Last Funding Round Amount",
    company_total_funding_raised: "Total Funding Raised",
    company_crunchbase_url: "Crunchbase URL",
  },
  "Financial Insights": {
    company_inferred_revenue: "Inferred Revenue",
  },
  "Employee Details": {
    company_number_of_employees: "Number of Employees",
    company_employee_count_range: "Employee Count Range",
    company_year_of_employment_data: "Year of Employment Data",
  },
  "Digital Transformation": {
    company_technologies: "Adoption of emerging technologies",
  },
  Occupation: {
    current_economic_activity_status: "Current Economic Activity Status",
    reason_for_not_seeking_work_past_week:
      "Reason for Not Seeking Work Past Week",
    last_looked_for_work: "Last Looked for Work",
    employment_status: "Employment Status",
    activity_during_the_past_12_months: "Activity During the Past 12 Months",
    occupation: "Occupation",
    industry: "Industry",
  },
  Schooling: {
    "attending_a_school/educational_institution":
      "Attending a School/Educational Institution",
    type_of_attendance: "Type of Attendance",
    "type_of_school/educational_institution_currently_attending":
      "Type of School/Educational Institution Currently Attending",
    highest_level_of_educational_attainment:
      "Highest Level of Educational Attainment",
    years_of_schooling_at_highest_level: "Years of Schooling at Highest Level",
    highest_qualification_ever_attained: "Highest Qualification Ever Attained",
    "gce_o/cxc_gen/sc_passes": "GCE O/CXC Gen/SC Passes",
    "gce_a/cape/hsc_passes": "GCE A/CAPE/HSC Passes",
  },
};

export const fieldOptions = {
  corporation: [
    { label: "Select Municipality", value: "" },
    { label: "Borough Of Arima", value: "BOROUGH OF ARIMA" },
    { label: "Borough Of Chaguanas", value: "BOROUGH OF CHAGUANAS" },
    { label: "Borough Of Point Fortin", value: "BOROUGH OF POINT FORTIN" },
    { label: "City Of Port Of Spain", value: "CITY OF PORT OF SPAIN" },
    { label: "City Of San Fernando", value: "CITY OF SAN FERNANDO" },
    { label: "Couva/ Tabaquite/ Talparo", value: "COUVA/ TABAQUITE/ TALPARO" },
    { label: "Diego Martin", value: "DIEGO MARTIN" },
    { label: "Mayaro/ Rio Claro", value: "MAYARO/ RIO CLARO" },
    { label: "Penal/ Debe", value: "PENAL/ DEBE" },
    { label: "Princes Town", value: "PRINCES TOWN" },
    { label: "San Juan / Laventille", value: "SAN JUAN / LAVENTILLE" },
    { label: "Sangre Grande", value: "SANGRE GRANDE" },
    { label: "Siparia", value: "SIPARIA" },
    { label: "Tunapuna/ Piarco", value: "TUNAPUNA/ PIARCO" },
  ],

  profile_link: [
    { label: "Has Profile Link", value: "" },
    { label: "True", value: "PROFILE_LINK" },
    { label: "False", value: "FALSE" },
  ],
  contact_url_company: [
    { label: "Has Company Url", value: "" },
    { label: "True", value: "CONTACT_URL_COMPANY" },
    { label: "False", value: "FALSE" },
  ],
  profile_title: [
    { label: "Has Title", value: "" },
    { label: "True", value: "PROFILE_TITLE" },
    { label: "False", value: "FALSE" },
  ],
  profile_summary: [
    { label: "Has Profile Summary", value: "" },
    { label: "True", value: "PROFILE_SUMMARY" },
    { label: "False", value: "FALSE" },
  ],
  skills: [
    { label: "Has Skills", value: "" },
    { label: "True", value: "SKILLS" },
    { label: "False", value: "FALSE" },
  ],
  languages: [
    { label: "Has Languages", value: "" },
    { label: "True", value: "LANGUAGES" },
    { label: "False", value: "FALSE" },
  ],
  honors: [
    { label: "Has Honors ", value: "" },
    { label: "True", value: "HONORS" },
    { label: "False", value: "FALSE" },
  ],
  publications: [
    { label: "Has Publications", value: "" },
    { label: "True", value: "PUBLICATIONS" },
    { label: "False", value: "FALSE" },
  ],
  certifications: [
    { label: "Has Certifications", value: "" },
    { label: "True", value: "CERTIFICATIONS" },
    { label: "False", value: "FALSE" },
  ],
  contact_url_portfolio: [
    { label: "Has Portfolio URL", value: "" },
    { label: "True", value: "CONTACT_URL_PORTFOLIO" },
    { label: "False", value: "FALSE" },
  ],
  contact_url_blog: [
    { label: "Has Personal Blog URL", value: "" },
    { label: "True", value: "CONTACT_URL_BLOG" },
    { label: "False", value: "FALSE" },
  ],
  contact_url_rss: [
    { label: "Has RSS Feed URL", value: "" },
    { label: "True", value: "CONTACT_URL_RSS" },
    { label: "False", value: "FALSE" },
  ],
  contact_url_other: [
    { label: "Has Other Contact URL", value: "" },
    { label: "True", value: "CONTACT_URL_OTHER" },
    { label: "False", value: "FALSE" },
  ],
  experience_json: [
    { label: "Has Experience Info", value: "" },
    { label: "True", value: "EXPERIENCE_JSON" },
    { label: "False", value: "FALSE" },
  ],
  education_json: [
    { label: "Has Education Info", value: "" },
    { label: "True", value: "EDUCATION_JSON" },
    { label: "False", value: "FALSE" },
  ],
  total_childs: [
    { label: "Select Children Status", value: "" },
    { label: "1 Child", value: "1  CHILD" },
    { label: "2 Children", value: "2  CHILDREN" },
    { label: "3 Children", value: "3  CHILDREN" },
    { label: "4 Children", value: "4  CHILDREN" },
    { label: "5 Children", value: "5  CHILDREN" },
    { label: "6 Children", value: "6  CHILDREN" },
    { label: "7 Children", value: "7  CHILDREN" },
    { label: "8 Children", value: "8  CHILDREN" },
    { label: "9 Children", value: "9  CHILDREN" },
    { label: "10 Children", value: "10  CHILDREN" },
    { label: "11 Children", value: "11  CHILDREN" },
    { label: "12 Children", value: "12  CHILDREN" },
    { label: "13 Children", value: "13  CHILDREN" },
    { label: "14 Children", value: "14  CHILDREN" },
    { label: "15 Children", value: "15  CHILDREN" },
    { label: "16 Children", value: "16  CHILDREN" },
    { label: "17 Children", value: "17  CHILDREN" },
    { label: "18 Children", value: "18  CHILDREN" },
    { label: "19 Children", value: "19  CHILDREN" },
    { label: "20 Children", value: "20  CHILDREN" },
    { label: "21 Children", value: "21  CHILDREN" },
    { label: "22 Children", value: "22  CHILDREN" },
    { label: "No Children", value: "NONE" },
  ],

  age_group: [
    { label: "Select Age Range", value: "" },
    { label: "Ineligible Voters", value: "0-18 (INELIGIBLE VOTERS)" },
    {
      label: "Youth/First-Time Voters",
      value: "18-24 (YOUTH/FIRST-TIME VOTERS)",
    },
    { label: "Young Professionals", value: "25-34 (YOUNG PROFESSIONALS)" },
    { label: "Young Families", value: "35-44 (YOUNG FAMILIES)" },
    {
      label: "Parents Of Teens/Adults",
      value: "45-64 (PARENTS OF TEENS/ADULTS)",
    },
    { label: "Retirees/Seniors", value: "65+ (RETIREES/SENIORS)" },
  ],

  eligible_for_voting: [
    { label: "Select Eligiblity", value: "" },
    { label: "Eligible", value: "ELIGIBLE" },
    { label: "Ineligible", value: "INELIGIBLE" },
    { label: "Not Stated", value: "NOT STATED" },
  ],

  unc_member_relation: [
    { label: "Select Relation", value: "" },
    { label: "UNC Member", value: "UNC MEMBER" },
    {
      label: "Family Memeber Of UNC Memebers",
      value: "FAMILY MEMBERS OF UNC MEMBERS",
    },
    {
      label: "Same Household As UNC Members",
      value: "SAME HOUSEHOLD AS UNC MEMBERS",
    },
    { label: "BLank", value: "NaN" },
  ],

  swing_voter_label: [
    { label: "Select Score", value: "" },
    { label: "Low Swing", value: "LOW SWING" },
    { label: "Intermediate Swing", value: "INTERMEDIATE SWING" },
    { label: "High Swing", value: "HIGH SWING" },
  ],

  importance_label: [
    { label: "Select Score", value: "" },
    { label: "Low Importance", value: "LOW IMPORTANCE" },
    { label: "Intermediate Importance", value: "INTERMEDIATE IMPORTANCE" },
    { label: "High Importance", value: "HIGH IMPORTANCE" },
  ],
  registered_for_election: [
    { label: "Select Status", value: "" },
    { label: "Registered", value: "REGISTERED" },
    { label: "Unregistered", value: "UNREGISTERED" },
  ],

  parliamentary_electoral_district: [
    { label: "Select Parliamentary Electoral District", value: "" },
    { label: "Arima", value: "ARIMA" },
    { label: "Arouca/Maloney", value: "AROUCA/MALONEY" },
    { label: "Barataria/San Juan", value: "BARATARIA /SAN JUAN" },
    { label: "Caroni Central", value: "CARONI CENTRAL" },
    { label: "Caroni East", value: "CARONI EAST" },
    { label: "Chaguanas East", value: "CHAGUANAS EAST" },
    { label: "Chaguanas West", value: "CHAGUANAS WEST" },
    { label: "Couva North", value: "COUVA NORTH" },
    { label: "Couva South", value: "COUVA SOUTH" },
    { label: "Cumuto/Manzanilla", value: "CUMUTO/MANZANILLA" },
    { label: "D'Abadie/O'Meara", value: "D'ABADIE/O'MEARA" },
    { label: "Diego Martin Central", value: "DIEGO MARTIN CENTRAL" },
    { label: "Diego Martin North/East", value: "DIEGO MARTIN NORTH/EAST" },
    { label: "Diego Martin West", value: "DIEGO MARTIN WEST" },
    { label: "Fyzabad", value: "FYZABAD" },
    { label: "La Brea", value: "LA BREA" },
    { label: "La Horquetta/Talparo", value: "LA HORQUETTA/TALPARO" },
    { label: "Laventille East/Morvant", value: "LAVENTILLE EAST/MORVANT" },
    { label: "Laventille West", value: "LAVENTILLE WEST" },
    { label: "Lopinot/Bon Air West", value: "LOPINOT/BON AIR WEST" },
    { label: "Mayaro", value: "MAYARO" },
    { label: "Moruga/Tableland", value: "MORUGA/TABLELAND" },
    { label: "NAPARIMA", value: "NAPARIMA" },
    { label: "Oropouche East", value: "OROPOUCHE EAST" },
    { label: "Oropouche West", value: "OROPOUCHE WEST" },
    { label: "Pointe-a-Pierre", value: "POINTE-A-PIERRE" },
    { label: "Point Fortin", value: "POINT FORTIN" },
    {
      label: "Port-of-Spain North/St. Ann's West",
      value: "PORT-OF-SPAIN NORTH/ST. ANN'S WEST",
    },
    { label: "Port-of-Spain South", value: "PORT-OF-SPAIN SOUTH" },
    { label: "Princes Town", value: "PRINCES TOWN" },
    { label: "San Fernando East", value: "SAN FERNANDO EAST" },
    { label: "San Fernando West", value: "SAN FERNANDO WEST" },
    { label: "Siparia", value: "SIPARIA" },
    { label: "St. Ann's East", value: "ST. ANN'S EAST" },
    { label: "St. Augustine", value: "ST. AUGUSTINE" },
    { label: "St. Joseph", value: "ST. JOSEPH" },
    { label: "Tabaquite", value: "TABAQUITE" },
    { label: "Tobago East", value: "TOBAGO EAST" },
    { label: "Tobago West", value: "TOBAGO WEST" },
    { label: "Toco/Sangre Grande", value: "TOCO/SANGRE GRANDE" },
    { label: "Tunapuna", value: "TUNAPUNA" },
  ],
  gender: [
    { label: "Select Gender", value: "" },
    { label: "Female", value: "FEMALE" },
    { label: "Male", value: "MALE" },
    { label: "Other", value: "NaN" },
  ],
  housing_status: [
    { label: "Select Status", value: "" },
    { label: "Owner", value: "OWNER" },
    { label: "Renter", value: "RENTER" },
    { label: "Not Stated", value: "NOT STATED" },
  ],
  ethnic_group: [
    { label: "Select Ethnic Group", value: "" },
    { label: "African", value: "AFRICAN" },
    { label: "Caucasian", value: "CAUCASIAN" },
    { label: "Chinese", value: "CHINESE" },
    { label: "East Indian", value: "EAST INDIAN" },
    { label: "Indigenous", value: "INDIGENOUS" },
    {
      label: "Mixed - African/ East Indian",
      value: "MIXED - AFRICAN/ EAST INDIAN",
    },
    { label: "Mixed - Other", value: "MIXED - OTHER" },
    { label: "Portuguese", value: "PORTUGUESE" },
    { label: "Syrian/ Lebanese", value: "SYRIAN/ LEBANESE" },
    { label: "Other Ethnic Group", value: "OTHER ETHNIC GROUP" },
    { label: "Not Stated", value: "NOT STATED" },
  ],
  religion: [
    { label: "Select Religion", value: "" },
    { label: "Anglican", value: "ANGLICAN" },
    { label: "Baptist - Other", value: "BAPTIST - OTHER" },
    {
      label: "Baptist - Spiritual Shouter Baptist",
      value: "BAPTIST - SPIRITUAL SHOUTER BAPTIST",
    },
    { label: "Hinduism", value: "HINDUISM" },
    { label: "Islam", value: "ISLAM" },
    { label: "Jehovah's Witness", value: "JEHOVAH'S WITNESS" },
    { label: "Methodist", value: "METHODIST" },
    { label: "Moravian", value: "MORAVIAN" },
    { label: "Orisha", value: "ORISHA" },
    {
      label: "Pentecostal/Evangelical/Full Gospel",
      value: "PENTECOSTAL/EVANGELICAL/FULL GOSPEL",
    },
    {
      label: "Presbyterian/Congregational",
      value: "PRESBYTERIAN/CONGREGATIONAL",
    },
    { label: "Rastafarian", value: "RASTAFARIAN" },
    { label: "Roman Catholic", value: "ROMAN CATHOLIC" },
    { label: "Seven Day Adventist", value: "SEVEN DAY ADVENTIST" },
  ],
  marital_status: [
    { label: "Select Marital Status", value: "" },
    { label: "Divorced", value: "DIVORCED" },
    { label: "Legally Separated", value: "LEGALLY SEPARATED" },
    { label: "Married", value: "MARRIED" },
    { label: "Never Married", value: "NEVER MARRIED" },
    { label: "Not Stated", value: "NOT STATED" },
    { label: "Widowed", value: "WIDOWED" },
    { label: "Blank", value: "BLANK" },
  ],
  pq2relation: [
    { label: "Select Relation", value: "" },
    {
      label: "Adopted Child Of Head & Spouse/Oartner",
      value: "ADOPTED CHILD OF HEAD & SPOUSE/PARTNER",
    },
    { label: "Child Of Head Only", value: "CHILD OF HEAD ONLY" },
    {
      label: "Child Of Head and Spouse/Partner",
      value: "CHILD OF HEAD AND SPOUSE/PARTNER",
    },
    {
      label: "Child Of Spouse/Partner Only",
      value: "CHILD OF SPOUSE/PARTNER ONLY",
    },
    { label: "Domestic Employee", value: "DOMESTIC EMPLOYEE" },
    {
      label: "Grandchild Of Head/Spouse/Partner",
      value: "GRANDCHILD OF HEAD/SPOUSE/PARTNER",
    },
    { label: "Head", value: "HEAD" },
    { label: "Other Non Relative", value: "OTHER NON RELATIVE" },
    {
      label: "Other Relative Of Head/Spouse/Partner",
      value: "OTHER RELATIVE OF HEAD/SPOUSE/PARTNER",
    },
    {
      label: "Parent Of Head/Spouse/Partner",
      value: "PARENT OF HEAD/SPOUSE/PARTNER",
    },
    { label: "Partner Of Head", value: "PARTNER OF HEAD" },
    { label: "Spouse Of Head", value: "SPOUSE OF HEAD" },
    { label: "Spouse/Partner Of Child", value: "SPOUSE/PARTNER OF CHILD" },
  ],
  company_source: [
    {
      label: "Select source",
      value: "",
    },
    {
      label: "Alpha",
      value: "ALPHA",
    },
    {
      label: "Beta",
      value: "BETA",
    },
    {
      label: "Gamma",
      value: "GAMMA",
    },
    {
      label: "Omega",
      value: "OMEGA",
    },
    {
      label: "Alpha Beta",
      value: "ALPHA_BETA",
    },
    {
      label: "Alpha Omega",
      value: "ALPHA_OMEGA",
    },
    {
      label: "Alpha Beta Omega",
      value: "ALPHA_BETA_OMEGA",
    },
    {
      label: "Beta Omega",
      value: "BETA_OMEGA",
    },
    {
      label: "Gamma Beta",
      value: "GAMMA_BETA",
    },
    {
      label: "Gamma Omega",
      value: "GAMMA_OMEGA",
    },
    {
      label: "Gamma Alpha Beta",
      value: "GAMMA_ALPHA_BETA",
    },
    {
      label: "Gamma Beta Omega",
      value: "GAMMA_BETA_OMEGA",
    },
    {
      label: "Gamma Alpha Beta Omega",
      value: "GAMMA_ALPHA_BETA_OMEGA",
    },
    {
      label: "Gamma Alpha",
      value: "GAMMA_ALPHA",
    },
    {
      label: "Gamma Alpha Omega",
      value: "GAMMA_ALPHA_OMEGA",
    },
  ],
};

export const selectFieldValues = {
  company_source: true,
  parliamentary_electoral_district: true,
  total_childs: true,
  age_group: true,
  eligible_for_voting: true,
  unc_member_relation: true,
  swing_voter_label: true,
  importance_label: true,
  registered_for_election: true,
  gender: true,
  housing_status: true,
  ethnic_group: true,
  religion: true,
  marital_status: true,
  pq2relation: true,
  profile_link: true,
  contact_url_company: true,
  profile_title: true,
  profile_summary: true,
  skills: true,
  languages: true,
  honors: true,
  certifications: true,
  publications: true,
  contact_url_portfolio: true,
  contact_url_blog: true,
  contact_url_rss: true,
  contact_url_other: true,
  experience_json: true,
  education_json: true,
};
export const AutoCompleteFields = {
  street_name: true,
  community: true,
  electoral_district: true,
  full_name_current: true,
  full_name_at_birth: true,
  father_given_names_at_birth: true,
  mother_given_names_at_birth: true,
  fb_2015_profile_url: true,
};

export const RangeFields = {
  turn_out_score: true,
  party_support_score: true,
  registration_score: true,
};
///////////////////////////////////////////////////////Companies Varaiables////////////////////////////////
export const searchDataComapniesInitalValues = {
  tableName: "companyinfo",
  company_name: "",
  company_business_address_2: "",
  company_business_address_3: "",
  company_source: "",
  company_city: "",
  company_country: "",
  company_mailing_address: "",
  company_fax_number: "",
  company_email: "",
  company_website: "",
  company_linkedin_url: "",
  company_crunchbase_url: "",
  company_facebook_url: "",
  company_twitter_url: "",
  company_number_of_employees: "",
  company_year_established: "",
  company_business_address: "",

  company_legal_code: "",
  company_ownership_code: "",
  company_year_began_operation: "",
  company_industry: "",
  company_contact_person: "",
  company_contact_position: "",
  company_sic_standard_industrial_classification: "",
  company_incorporation_type: "",
  company_legal_status: "",
  company_company_identifier: "",
  comapny_irn: "",
  company_employee_count_range: "",
  company_year_of_employment_data: "",
  comapny_describtion: "",
  company_technologies: "",
  company_inferred_revenue: "",
  company_last_funding_date: "",
  company_latest_funding_stage: "",
  company_number_of_funding_rounds: "",
  company_last_funding_round_ammount: "",
  company_total_funding_raised: "",
  company_products: "",
  company_logo_url_primary: "",
  company_logo_url_secondary: "",
  company_number_of_retail_locations: "",
  company_postal_code: "",
  company_other_business_names: "",
  company_telephone_number: "",
  filterColumn: "",
  order: "",
  FilterStatments: "",
};

export const defaultCompaniesColumns = [
  {
    name: "company_name",
    label: "Company Name",
    visible: true,
  },
  {
    name: "company_other_business_names",
    label: "Other Business Names",
    visible: true,
  },
  {
    name: "company_year_established",
    label: "Year Established",
    visible: true,
  },
  {
    name: "comapny_describtion",
    label: "Description",
    visible: true,
  },
  {
    name: "company_industry",
    label: "Industry",
    visible: true,
  },
  {
    name: "company_sic_standard_industrial_classification",
    label: "SIC",
    visible: true,
  },
  {
    name: "company_year_began_operation",
    label: "Year began operation",
    visible: true,
  },
  {
    name: "company_legal_code",
    label: "Legal Code",
    visible: true,
  },
  {
    name: "company_ownership_code",
    label: "Ownership Code",
    visible: true,
  },
  {
    name: "company_products",
    label: "Products",
    visible: true,
  },
  {
    name: "company_logo_url_primary",
    label: "Logo Url Primary",
    visible: true,
  },
  {
    name: "company_logo_url_secondary",
    label: "Logo Url Secondary",
    visible: true,
  },
  {
    name: "company_company_identifier",
    label: "Company Identifier",
    visible: true,
  },
  {
    name: "comapny_irn",
    label: "IRN",
    visible: true,
  },
  {
    name: "company_incorporation_type",
    label: "Incorporation Type",
    visible: true,
  },
  {
    name: "company_legal_status",
    label: "Legal Status",
    visible: true,
  },
  {
    name: "company_city",
    label: "City",
    visible: true,
  },
  {
    name: "company_country",
    label: "Country",
    visible: true,
  },
  {
    name: "company_business_address",
    label: "Business Address 1",
    visible: true,
  },
  {
    name: "company_business_address_2",
    label: "Business Address 2",
    visible: true,
  },
  {
    name: "company_business_address_3",
    label: "Business Address 3",
    visible: true,
  },
  {
    name: "company_postal_code",
    label: "Zip Code",
    visible: true,
  },
  {
    name: "company_mailing_address",
    label: "Mailing Address",
    visible: true,
  },
  {
    name: "company_telephone_number",
    label: "Telephone Number",
    visible: true,
  },
  {
    name: "company_fax_number",
    label: "Fax Number",
    visible: true,
  },
  {
    name: "company_email",
    label: "Email Address",
    visible: true,
  },
  {
    name: "company_website",
    label: "Website Address",
    visible: true,
  },
  {
    name: "company_linkedin_url",
    label: "LinkedIn",
    visible: true,
  },
  {
    name: "company_facebook_url",
    label: "Facebook",
    visible: true,
  },
  {
    name: "company_twitter_url",
    label: "Twitter",
    visible: true,
  },
  {
    name: "company_contact_person",
    label: "Contact Person Name",
    visible: true,
  },
  {
    name: "company_contact_position",
    label: "Contact Person Position",
    visible: true,
  },
  {
    name: "company_number_of_retail_locations",
    label: "Number of retail locations",
    visible: true,
  },
  {
    name: "company_number_of_employees",
    label: "Number of employees",
    visible: true,
  },
  {
    name: "company_employee_count_range",
    label: "Employee count range",
    visible: true,
  },
  {
    name: "company_year_of_employment_data",
    label: "Year of employment data",
    visible: true,
  },
  {
    name: "company_last_funding_date",
    label: "Last funding date",
    visible: true,
  },
  {
    name: "company_latest_funding_stage",
    label: "Latest funding stage",
    visible: true,
  },
  {
    name: "company_number_of_funding_rounds",
    label: "Number of funding rounds",
    visible: true,
  },
  {
    name: "company_last_funding_round_ammount",
    label: "Last funding round amount",
    visible: true,
  },
  {
    name: "company_crunchbase_url",
    label: "Crunchbase Url",
    visible: true,
  },
  {
    name: "company_total_funding_raised",
    label: "Total funding raised",
    visible: true,
  },
  {
    name: "company_inferred_revenue",
    label: "Inferred revenue",
    visible: true,
  },
  {
    name: "company_technologies",
    label: "Adoption of emerging technologies",
    visible: true,
  },
  {
    name: "company_source_raw",
    label: "Company Source",
    visible: true,
  },
];

export const Companiescategories = {
  "General Information": [
    { value: "company_name", name: "Company Name" },
    {
      value: "company_other_business_names",
      name: "Other Business Names",
    },
    { value: "company_year_established", name: "Year Established" },

    {
      value: "comapny_describtion",
      name: "Description",
    },
    {
      value: "company_industry",
      name: "Industry",
    },
    {
      value: "company_sic_standard_industrial_classification",
      name: "SIC",
    },
    {
      value: "company_year_began_operation",
      name: "Year began operation",
    },
    {
      value: "company_legal_code",
      name: "Legal Code",
    },
    {
      value: "company_ownership_code",
      name: "Ownership Code",
    },
    {
      value: "company_products",
      name: "Products",
    },
    {
      value: "company_logo_url_primary",
      name: "Logo Url Primary",
    },
    {
      value: "company_logo_url_secondary",
      name: "Logo Url Secondary",
    },
  ],
  "Legal Status": [
    { value: "company_company_identifier", name: "Company Identifier" },
    { value: "comapny_irn", name: "IRN" },
    { value: "company_incorporation_type", name: "Incorporation Type" },
    { value: "company_legal_status", name: "Legal Status" },
  ],
  Address: [
    { value: "company_city", name: "City" },
    { value: "company_country", name: "Country" },
    { value: "company_business_address", name: "Business Address 1" },
    { value: "company_business_address_2", name: "Business Address 2" },
    { value: "company_business_address_3", name: "Business Address 3" },
    { value: "company_postal_code", name: "Zip Code" },
    { value: "company_mailing_address", name: "Mailing Address" },
  ],
  "Phone Numbers": [
    { value: "company_telephone_number", name: "Telephone Number" },
    { value: "company_fax_number", name: "Fax Number" },
  ],
  Emails: [
    { value: "company_email", name: "Email Address" },

    { value: "company_website", name: "Website Address" },
    {
      value: "company_linkedin_url",
      name: "LinkedIn",
    },
    { value: "company_facebook_url", name: "Facebook" },
    { value: "company_twitter_url", name: "Twitter" },
  ],
  "Contact Person": [
    { value: "company_contact_person", name: "Contact Person Name" },
    { value: "company_contact_position", name: "Contact Person Position" },
  ],
  //////////////////////// to be added in table and determine if auto or select fields
  "Corporate Structure": [
    {
      value: "company_number_of_retail_locations",
      name: "Number of retail locations",
    },
  ],
  "Employee Details": [
    { value: "company_number_of_employees", name: "Number of employees" },
    { value: "company_employee_count_range", name: "Employee count range" },
    {
      value: "company_year_of_employment_data",
      name: "Year of employment data",
    },
  ],
  ///////////////////////////////////////////////////// to be added in table and determine if auto or select fields
  Funding: [
    {
      value: "company_last_funding_date",
      name: "Last funding date",
    },
    {
      value: "company_latest_funding_stage",
      name: "Latest funding stage",
    },
    {
      value: "company_number_of_funding_rounds",
      name: "Number of funding rounds",
    },
    {
      value: "company_last_funding_round_ammount",
      name: "Last funding round amount",
    },
    {
      value: "company_total_funding_raised",
      name: "Total funding raised",
    },
    {
      value: "company_crunchbase_url",
      name: "Crunchbase Url",
    },
  ],
  "Financial Insights": [
    { value: "company_inferred_revenue", name: "Inferred revenue" },
  ],
  "Digital Transformation": [
    {
      value: "company_technologies",
      name: "Adoption of emerging technologies",
    },
  ],

  Data: [{ value: "company_source", name: "Company Source" }],
};
export const companyFieldsToCheck = [
  "company_name",
  "company_source",
  "company_business_address_2",
  "company_business_address_3",
  "company_city",
  "company_country",
  "company_mailing_address",
  "company_fax_number",
  "company_email",
  "company_website",
  "company_linkedin_url",
  "company_crunchbase_url",
  "company_facebook_url",
  "company_twitter_url",
  "company_number_of_employees",
  "company_year_established",
  "company_business_address",

  "company_legal_code",
  "company_ownership_code",
  "company_year_began_operation",
  "company_industry",
  "company_contact_person",
  "company_contact_position",
  "company_sic_standard_industrial_classification",
  "company_incorporation_type",
  "company_legal_status",
  "company_company_identifier",
  "comapny_irn",
  "company_employee_count_range",
  "company_year_of_employment_data",
  "comapny_describtion",
  "company_technologies",
  "company_inferred_revenue",
  "company_last_funding_date",
  "company_latest_funding_stage",
  "company_number_of_funding_rounds",
  "company_last_funding_round_ammount",
  "company_total_funding_raised",
  "company_products",
  "company_logo_url_primary",
  "company_logo_url_secondary",
  "company_number_of_retail_locations",
  "company_postal_code",
  "company_other_business_names",
  "company_telephone_number",
];
export const companyAutoCompleteFields = {
  company_name: true,
  company_business_address_2: true,
  company_business_address_3: true,
  company_city: true,
  company_country: true,
  company_mailing_address: true,
  company_fax_number: true,
  company_email: true,
  company_website: true,
  company_linkedin_url: true,
  company_crunchbase_url: true,
  company_facebook_url: true,
  company_twitter_url: true,
  company_number_of_employees: true,
  company_year_established: true,
  company_business_address: true,

  company_legal_code: true,
  company_ownership_code: true,
  company_year_began_operation: true,
  company_industry: true,
  company_contact_person: true,
  company_contact_position: true,
  company_sic_standard_industrial_classification: true,
  company_incorporation_type: true,
  company_legal_status: true,
  company_company_identifier: true,
  comapny_irn: true,
  company_employee_count_range: true,
  company_year_of_employment_data: true,
  comapny_describtion: true,
  company_technologies: true,
  company_inferred_revenue: true,
  company_last_funding_date: true,
  company_latest_funding_stage: true,
  company_number_of_funding_rounds: true,
  company_last_funding_round_ammount: true,
  company_total_funding_raised: true,
  company_products: true,
  company_logo_url_primary: true,
  company_logo_url_secondary: true,
  company_number_of_retail_locations: true,
  company_postal_code: true,
  company_other_business_names: true,
  company_telephone_number: true,
};
