import React, { useEffect, useState } from "react";
import { Card, Col, Dropdown, Nav, Row, Tab } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../styles/ProfilePage.css";
import ProfileHeader from "../profileheader";
import { CircularProgress } from "@mui/material";
import Box from "@mui/material/Box";
import FamilyTree from "../familyTree";
import { Profilecategories, columnMapping } from "../../data/searchpage/data";
import { toProperCase } from "../../helperFunctions/functions";
import { Tooltip } from "@mui/material";

import {
  FetchingCategoryDetails,
  FetchingUserFamilyData,
  ProfileHeaderData,
} from "../../services/profilePageApis/api";
import Typography from "@mui/material/Typography";
import "../styles/index.css";
function VoterProfile() {
  const VISIBLE_CATEGORIES = 3;

  const [familyData, setFamilyData] = useState(null);
  const [noDataMessage, setNoDataMessage] = useState("");
  const VISIBLE_DETAILS_COUNT = 50;
  const [visibleDetails, setVisibleDetails] = useState([]);
  const [linked_in_id, setLinked_in_id] = useState("");
  const { id } = useParams();
  const history = useHistory();
  const [rowData, setRowData] = useState(null);
  const [activeTab, setActiveTab] = useState("Name");
  const [categoryData, setCategoryData] = useState({});
  const [loading, setLoading] = useState(false);
  const [displayedCategories, setDisplayedCategories] = useState(
    Object.keys(Profilecategories).slice(0, VISIBLE_CATEGORIES)
  );
  const [majorCategory, setMajorCategory] = useState("Personal");

  ///***********************Functions********************** */
  const handleMajorCategory = (category) => {
    setMajorCategory(category);
    setActiveTab(
      category === "Personal"
        ? "Name"
        : category === "Professional"
        ? "Experience"
        : "Household Info"
    );
  };

  /////1. Setting the active Category ////////
  ///////////////
  const handleTabClick = (category) => {
    if (category === "Family Tree") {
      setMajorCategory("");
    }
    setActiveTab(category);

    if (!displayedCategories.includes(category)) {
      let newDisplayedCategories = [...displayedCategories];
      newDisplayedCategories.pop();
      newDisplayedCategories.push(category);
      setDisplayedCategories(newDisplayedCategories);
    }

    if (category === "Family Tree") {
      fetchFamilyData(decodedID).then((data) => {
        setFamilyData(data);
      });
    }
  };

  /////2. getting the data of the active tab every time it changes
  useEffect(() => {
    if (linked_in_id) {
      getCategoryAPI(activeTab);
    }
  }, [activeTab, linked_in_id]);

  //////***** Getting the data to pass it to the profile header then  */
  let decodedID;
  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await ProfileHeaderData(decodedID);
        setRowData(data);

        setLinked_in_id(data.li_all_index);
      } catch (err) {
        toast.error("Error while fetching details, Please try again");
        history.push("/");
      }
    };

    fetchData();
  }, [decodedID]);

  ///// Redirecting if the user entered any thing in the url
  try {
    decodedID = id;
  } catch (error) {
    toast.error("Invalid ID");
    history.push("/");
    return null;
  }
  ////// Getting the family members of the user and then pass it to the family tree component
  const fetchFamilyData = async (uid) => {
    try {
      const data = await FetchingUserFamilyData(uid);
      return data;
    } catch (error) {
      console.log(error);
      toast.error("Error while fetching details, Please try again");
      history.push("/");
    }
  };

  /////// Rendering the Fields of each category automatically
  function renderDetail(key, value) {
    const customColumns = columnMapping[activeTab];
    if (customColumns && !customColumns[key]) {
      return null;
    }
    const displayName = customColumns ? customColumns[key] : key;
    if (
      (key === "experience_json" || key === "education_json") &&
      value !== "NaN" &&
      value !== "[]" &&
      value !== "{}" &&
      value !== ""
    ) {
      let correctedDataString = value.replace(/'/g, "");
      correctedDataString = correctedDataString.replace(/\\+"/g, '"');
      correctedDataString = correctedDataString.replace(/\\s/g, "'s");

      let experiencesArray;
      try {
        experiencesArray = JSON.parse(correctedDataString);
      } catch (e) {
        return (
          <div className="w-full md:w-1/2 mt-3" key={key}>
            <p className="text-lg font-semibold">
              {key === "experience_json" ? "Experience" : "Education"}:
            </p>
            <p title="ParsingError" className="mx-4 mt-3 text-gray-600">
              N/A
            </p>
          </div>
        );
      }
      return experiencesArray.map((exp, index) => {
        const experienceKey = Object.keys(exp)[0];
        const experience = exp[experienceKey];

        return (
          <div key={index} className="bg-white rounded my-3">
            <div className="p-0">
              <h5 className="text-xl font-bold mb-3 text-start">
                {key === "experience_json"
                  ? `Experience ${toProperCase(
                      Object.keys(exp)[0].replace("exp_", "")
                    )}`
                  : `Education ${toProperCase(
                      Object.keys(exp)[0].replace("educ_", "")
                    )}`}
              </h5>
              {Object.entries(experience).map(([detailKey, detailValue]) => {
                // Skip rendering for company_score and title_score
                if (
                  detailKey === "company_score" ||
                  detailKey === "title_score"
                ) {
                  return null;
                }

                return (
                  <div key={detailKey} className="text-start flex items-start">
                    {/* First p tag as the label with bold styling */}
                    <p className="text-lg font-semibold  w-1/6">
                      {toProperCase(detailKey.replace("_", " "))}:
                    </p>
                    {/* Second p tag for displaying the large amount of text */}
                    <p className="text-base text-gray-700 flex-1 overflow-hidden">
                      {detailValue === "" ? "N/A" : detailValue}
                    </p>
                  </div>
                );
              })}
            </div>
          </div>
        );
      });
    } else if (key === "companies") {
      const sortedCompanies = value.sort((a, b) => a.order - b.order);

      return (
        <div className="w-full md:w-1/2 mt-3 text-start" key={key}>
          <p className="text-lg font-semibold">Companies:</p>
          {sortedCompanies.length > 0 ? (
            sortedCompanies.map((company, idx) => (
              <div
                key={company.id}
                className="text-start flex items-start my-2"
                onClick={() =>
                  window.open(`/companydetails/${company.id}`, "_blank")
                }
              >
                <Tooltip title="Click to open company profile" arrow>
                  <p className="text-base text-gray-700 flex-1 cursor-pointer">
                    {idx + 1}. {toProperCase(company.name)}{" "}
                    <span
                      className={
                        company.status === "LATEST"
                          ? "text-green-600"
                          : "text-gray-700"
                      }
                    >
                      ( {toProperCase(company.status)} )
                    </span>
                  </p>
                </Tooltip>
              </div>
            ))
          ) : (
            <p className="text-base text-gray-700">
              No info about the companies.
            </p>
          )}
        </div>
      );
    } else if (value === "") {
      return (
        <div className="text-start flex items-start" key={key}>
          <p
            title="N/A"
            className="text-base text-gray-700 flex-1 overflow-hidden"
          >
            N/A
          </p>
        </div>
      );
    } else {
      const formattedValue = toProperCase(value);

      return (
        <div className="text-start flex items-start" key={key}>
          <p className="text-lg font-semibold  w-1/2">{displayName}:</p>
          <p
            title={formattedValue}
            className="text-base text-gray-700 flex-1 overflow-hidden"
          >
            {formattedValue}
          </p>
        </div>
      );
    }
  }

  ///// Fetching the data for each category except the FT
  const getCategoryAPI = async (category) => {
    setNoDataMessage("");

    if (category === "Family Tree" || category === "Map") {
      setVisibleDetails([]);
      return;
    }
    setCategoryData({});
    setVisibleDetails([]);
    setLoading(true);
    let responseData;
    try {
      responseData = await FetchingCategoryDetails(
        linked_in_id,
        decodedID,
        category
      );
      if (responseData.message) {
        setNoDataMessage("No Data Available");
        setLoading(false);
        return;
      }

      const orderedKeys = Object.keys(columnMapping[activeTab]);
      const relevantData = orderedKeys.reduce((obj, key) => {
        if (responseData[key] || responseData[key] === null) {
          obj[key] = responseData[key];
        }
        return obj;
      }, {});

      setCategoryData(relevantData);
    } catch (error) {
      toast.error("An error occurred while fetching data.");
    } finally {
      setLoading(false);
    }
  };

  if (!rowData) {
    return (
      <div className="flex justify-center items-center h-24">
        <CircularProgress style={{ color: "black" }} />
      </div>
    );
  }

  // Render the detailed data
  return (
    <div className="flex h-screen">
      {/* Left Column for Profile Header */}
      <div className=" w-full md:w-1/5 bg-white-50 overflow-y-auto">
        <ProfileHeader data={rowData} />
      </div>

      {/* Right Column for Tabs, Placeholder, and Content */}
      <div
        className="w-full md:w-4/5 bg-gray-50 py-2 px-4"
        style={{ height: "fit-content" }}
      >
        <div className="w-full mb-3">
          {/*<h2 className="self-stretch text-slate-700 text-2xl font-semibold text-left p-2 leading-loose tracking-tight">
            Election Info and Statistics
  </h2>*/}
        </div>

        <div className="w-full bg-white  rounded-lg">
          <div className="flex flex-wrap">
            <button
              className={`flex-shrink max-w-xs sm:max-w-sm md:w-1/5 lg:w-1/12 h-14 mr-1 rounded-t-md overflow-hidden text-ellipsis whitespace-nowrap ${
                majorCategory === "Personal"
                  ? "bg-white text-slate-700 border-t-2 border-r-2 border-custom-green-500"
                  : "bg-custom-green-500 text-white"
              }`}
              onClick={() => handleMajorCategory("Personal")}
            >
              Personal
            </button>
            <button
              className={`flex-shrink max-w-xs sm:max-w-sm md:w-1/5 lg:w-1/12 h-14 mr-1 rounded-t-md overflow-hidden text-ellipsis whitespace-nowrap tracking-tighter ${
                activeTab === "Family Tree"
                  ? "bg-white text-slate-700 border-t-2 border-r-2 border-custom-green-500"
                  : "bg-custom-green-500 text-white"
              }`}
              onClick={() => handleTabClick("Family Tree")}
            >
              Family Tree
            </button>
            <button
              className={`flex-shrink max-w-xs sm:max-w-sm md:w-1/5 lg:w-1/12 h-14 mr-1 rounded-t-md overflow-hidden text-ellipsis whitespace-nowrap tracking-tighter ${
                majorCategory === "Professional"
                  ? "bg-white text-slate-700 border-t-2 border-r-2 border-custom-green-500"
                  : "bg-custom-green-500 text-white"
              }`}
              onClick={() => handleMajorCategory("Professional")}
            >
              Professional
            </button>
            <button
              className={`flex-shrink max-w-xs sm:max-w-sm md:w-1/5 lg:w-1/12 h-14 mr-1 rounded-t-md overflow-hidden text-ellipsis whitespace-nowrap tracking-tighter ${
                majorCategory === "Household"
                  ? "bg-white text-slate-700 border-t-2 border-r-2 border-custom-green-500"
                  : "bg-custom-green-500 text-white"
              }`}
              onClick={() => handleMajorCategory("Household")}
            >
              Household
            </button>
          </div>

          {majorCategory &&
            (majorCategory === "Personal" ? (
              <div className="flex flex-wrap bg-white justify-start rounded-md">
                {[
                  "Name",
                  "Address",
                  "Age",
                  "Contact",
                  "Social",
                  "Immigration",
                  "Disability",
                  "Health",
                  "Fertility",
                  "Demographics",
                ].map((subTab) => (
                  <button
                    key={subTab}
                    className={`m-1 p-2 text-sm md:text-base ${
                      activeTab === subTab
                        ? "bg-white text-slate-700 border-b-2 border-custom-green-500"
                        : "text-slate-700 bg-white"
                    }`}
                    onClick={() => handleTabClick(subTab)}
                  >
                    {subTab}
                  </button>
                ))}
              </div>
            ) : majorCategory === "Professional" ? (
              <div className="flex flex-wrap bg-white justify-start rounded-md">
                {[
                  "Experience",
                  "Education",
                  "Professional",
                  "Occupation",
                  "Companies",
                  "Schooling",
                ].map((subTab) => (
                  <button
                    key={subTab}
                    className={`m-1 tracking-tight md:m-2 p-2 md:p-3 text-sm md:text-base ${
                      activeTab === subTab
                        ? "bg-white text-slate-700 border-b-2 border-custom-green-500"
                        : "text-slate-700 bg-white"
                    }`}
                    onClick={() => handleTabClick(subTab)}
                  >
                    {subTab}
                  </button>
                ))}
              </div>
            ) : majorCategory === "Household" ? (
              <div className="flex flex-wrap bg-white justify-start rounded-md">
                {["Household Info", "ICT", "Housing", "Environment"].map(
                  (subTab) => (
                    <button
                      key={subTab}
                      className={`m-1 p-2 text-sm md:text-base ${
                        activeTab === subTab
                          ? "bg-white text-slate-700 border-b-2 border-custom-green-500"
                          : "text-slate-700 bg-white"
                      }`}
                      onClick={() => handleTabClick(subTab)}
                    >
                      {subTab}
                    </button>
                  )
                )}
              </div>
            ) : null)}

          <div className="w-full">
            {/* Tab content */}
            <div className="p-4 flex-grow">
              {Object.keys(Profilecategories).map(
                (category, idx) =>
                  activeTab === category && (
                    <div key={`${category}-${idx}`}>
                      <div className="bg-transparent mb-4 ">
                        <div className="bg-transparent mb-4  ">
                          {" "}
                          {/* removed grid grid-cols-2*/}
                          {loading ? (
                            <div className="flex justify-start items-start h-24">
                              <CircularProgress />
                            </div>
                          ) : category === "Family Tree" ? (
                            <>
                              <p className="text-slate-700 text-xl font-semibold text-left">
                                {toProperCase(rowData.full_name)} family tree
                              </p>
                              {familyData ? (
                                <FamilyTree
                                  userID={rowData.id}
                                  initialData={familyData}
                                />
                              ) : (
                                <div className="flex justify-start items-start h-24">
                                  <CircularProgress />
                                </div>
                              )}
                            </>
                          ) : categoryData &&
                            Object.keys(categoryData).length === 0 ? (
                            <p
                              title="N/A"
                              class="text-base text-start text-gray-700 flex-1 overflow-hidden"
                            >
                              No information available
                            </p>
                          ) : (
                            categoryData &&
                            Object.entries(categoryData)
                              .slice(0, VISIBLE_DETAILS_COUNT)
                              .map(([key, value]) => renderDetail(key, value))
                          )}
                        </div>
                      </div>
                    </div>
                  )
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VoterProfile;
