export const categories = {
  textFields: [
    { value: "full_name_current", name: "Current Name" },
    { value: "full_name_at_birth", name: "Full Name At Birth" },
    { value: "father_given_names_at_birth", name: "Father's Given Name" },
    { value: "mother_given_names_at_birth", name: "Mother's Given Name" },
    { value: "gender", name: "Gender" },
    { value: "ethnic_group", name: "Ethnic Group" },
    { value: "religion", name: "Religion" },
    { value: "marital_status", name: "Marital Status" },
    { value: "phone_number", name: "Phone Number" },
    { value: "email", name: "Email" },
    { value: "street_name", name: "Street Name" },
    { value: "community", name: "Community" },
    { value: "corporation", name: "Corporation" },
    {
      value: "parliamentary_electoral_district",
      name: "Parliamentary Electoral District",
    },
    {
      value: "blk_bld_phase",
      name: "Blk/Blk phase",
    },
    {
      value: "municipal_electoral_district",
      name: "Municipal Electoral District",
    },
    {
      value: "relationship_to_head_of_household",
      name: "Relationship To Head Of Household",
    },
  ],
  numberFields: [
    { value: "polling_division", name: "Polling Division" },
    {
      value: "person_number",
      name: "Number Of Persons In Household",
    },
  ],
};

export const companyFiltercategories = {
  textFields: [
    { value: "company_name", name: "Company Name" },
    {
      value: "company_other_business_names",
      name: "Other Business Names",
    },
    { value: "company_year_established", name: "Year Established" },

    {
      value: "comapny_describtion",
      name: "Description",
    },
    {
      value: "company_industry",
      name: "Industry",
    },
    {
      value: "company_sic_standard_industrial_classification",
      name: "SIC",
    },
    {
      value: "company_year_began_operation",
      name: "Year began operation",
    },
    {
      value: "company_legal_code",
      name: "Legal Code",
    },
    {
      value: "company_ownership_code",
      name: "Ownership Code",
    },
    {
      value: "company_products",
      name: "Products",
    },
    {
      value: "company_logo_url_primary",
      name: "Logo Url Primary",
    },
    {
      value: "company_logo_url_secondary",
      name: "Logo Url Secondary",
    },
    { value: "company_company_identifier", name: "Company Identifier" },
    { value: "comapny_irn", name: "IRN" },
    { value: "company_incorporation_type", name: "Incorporation Type" },
    { value: "company_legal_status", name: "Legal Status" },
    { value: "company_city", name: "City" },
    { value: "company_country", name: "Country" },
    { value: "company_business_address", name: "Business Address 1" },
    { value: "company_business_address_2", name: "Business Address 2" },
    { value: "company_business_address_3", name: "Business Address 3" },
    { value: "company_postal_code", name: "Zip Code" },
    { value: "company_mailing_address", name: "Mailing Address" },
    { value: "company_telephone_number", name: "Telephone Number" },
    { value: "company_fax_number", name: "Fax Number" },
    { value: "company_email", name: "Email Address" },

    { value: "company_website", name: "Website Address" },
    {
      value: "company_linkedin_url",
      name: "LinkedIn",
    },
    { value: "company_facebook_url", name: "Facebook" },
    { value: "company_twitter_url", name: "Twitter" },
    { value: "company_contact_person", name: "Contact Person Name" },
    { value: "company_contact_position", name: "Contact Person Position" },
    {
      value: "company_number_of_retail_locations",
      name: "Number of retail locations",
    },
    { value: "company_number_of_employees", name: "Number of employees" },
    { value: "company_employee_count_range", name: "Employee count range" },
    {
      value: "company_year_of_employment_data",
      name: "Year of employment data",
    },
    {
      value: "company_last_funding_date",
      name: "Last funding date",
    },
    {
      value: "company_crunchbase_url",
      name: "Crunchbase Url",
    },
    {
      value: "company_technologies",
      name: "Adoption of emerging technologies",
    },
    {
      value: "company_latest_funding_stage",
      name: "Latest funding stage",
    },
    {
      value: "company_number_of_funding_rounds",
      name: "Number of funding rounds",
    },
    {
      value: "company_last_funding_round_ammount",
      name: "Last funding round amount",
    },
    {
      value: "company_total_funding_raised",
      name: "Total funding raised",
    },
    { value: "company_inferred_revenue", name: "Inferred revenue" },
  ],
};
export const conditions = {
  text: [
    "equals",
    "doesntEqual",
    "contains",
    "doesntContain",
    "startsWith",
    "endswith",
    "doesntstartwith",
    "doesntendwith",
  ],
  number: ["equals", "doesntEqual", "greater", "less", "greatEq", "lessEq"],
};

export const conditionLabels = {
  equals: "Equals",
  doesntEqual: "Doesn't Equal",
  contains: "Contains",
  doesntContain: "Doesn't Contain",
  startsWith: "Starts with",
  endswith: "Ends with",
  doesntstartwith: "Doesn't Start with",
  doesntendwith: "Doesn't End with",
  greater: "Greater than",
  less: "Less than",
  greatEq: "Greater than or equal",
  lessEq: "Less than or equal",
};
